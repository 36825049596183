import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {AuthModel} from '../../models/auth.model';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(email: string, password: string): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${API_URL}/login`, {
      email,
      password,
    });
  }

  loginWithGoogle(token: string): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${API_URL}/loginWithGoogle`, token);
  }
  signUp(user: any): Observable<any> {
    return this.http.post<any>(`${API_URL}/sign-up`,user);
  }
  updatePassword(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_URL}/auth/new-password`, {
      email,
      password
    });
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<string> {
    return this.http.post(`${API_URL}/auth/forgot-password`, {
      email,
    },{ responseType: 'text' });
  }

  getUserByToken(token: string): Observable<any> {
    if (!token) {
      // If token doesn't exist, return undefined.
      return of(undefined);
    }

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<any>(`${API_URL}/user`, {
      headers: httpHeaders,
    });
  }
  verifyUser(email: string): Observable<any> {
    return this.http.get<any>(`${API_URL}/apifew/verifyUser?userLogin=${email}`);
  }
  checkTokenExpiration(token: string): Observable<any> {
    return this.http
      .get<any>(`${API_URL}/auth/check-token-expiration?token=${token}`,
        {observe: 'response'}
      );
  }
}
