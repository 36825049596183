import { Injectable } from '@angular/core';
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SharedFunctionsService {

  constructor() { }

  /**
   * This will return the initials of each part of the full name, separated by a space
   * @param fullName
   */
  getInitials(fullName : string): string {
    const parts = fullName.split(' ');
    let initials = '';
    parts.forEach((part,index) => {
      if (part && index<2) {
        initials += part[0];
      }
    });
    return initials.toUpperCase();
  }
   setHeaders(name:string): HttpHeaders {
    let headers = new HttpHeaders();
    return headers.set(name, 'true');
  }

  getTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

}
