import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  private overlaySubject = new BehaviorSubject<{ visible: boolean; message: string }>({ visible: false, message: '' });
  overlayState$ = this.overlaySubject.asObservable();
  constructor() { }

  showOverlay(message : string): void {
    this.overlaySubject.next({visible:true,message});
  }

  hideOverlay(message : string): void {
    this.overlaySubject.next({visible:false,message});
  }

  isVisible():boolean{
    return this.overlaySubject.value.visible
  }
}
