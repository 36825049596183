import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {SharedFunctionsService} from "../shared-functions/shared-functions.service";

const API_URL = `${environment.apiUrl}/campaigns`;

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  /**
   * @param http : HttpClient
   * @param sharedFunctionsService : SharedFunctionsService
   */
  constructor(private http: HttpClient,private sharedFunctionsService:SharedFunctionsService) {
  }


  getCampaigns(dvRequest:any,from?:string) {
    let headers
    if(from) {
      headers = this.sharedFunctionsService.setHeaders('Skip-Loader');
    }
    return this.http.post<any>(`${API_URL}/get-campaigns`, dvRequest,{headers});
  }
}
