import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth.service';
import {Observable} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {AuthHTTPService} from "../auth-http";
import {environment} from "../../../../../environments/environment";
import {SwalNotificationsService} from "../../../../shared";
import {TokenService} from "../token.service";
import {parseJson} from "@angular/cli/src/utilities/json-file";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanActivateChild{
  /**
   * @param tokenExpiredService : TokenService
   * @param authHTTPService : AuthHTTPService
   * @param swalNotifs : SwalNotificationsService
   * @param authService : AuthService
   */
  constructor(  private tokenExpiredService: TokenService,
                private authHTTPService: AuthHTTPService,
                private swalNotifs: SwalNotificationsService,
                private authService : AuthService) {
  }
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.verifyUser(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }

  private verifyUser(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const lsValue = localStorage.getItem(this.authLocalStorageToken);
    let token = '';
    if (lsValue) {
      token = JSON.parse(lsValue).authToken;
    }

    /**
     * Previous role of the user
     */
    let role = localStorage.getItem('role')

    // Call the backend to verify user by token
    return this.authHTTPService.getUserByToken(token).pipe(
      switchMap(user => {
        if (this.isUserValid(user, role)) {

          this.updateCurrentPartnerAndUser(user)

          // If the user is valid, allow route activation
          return [true];
        } else {
          // If the user not valid, log out and redirect to login
          this.tokenExpiredService.logout();
          return [false];
        }
      }),
      catchError((error) => {
        /**
         * Handle error when fetching the user fails.
         */
        if (error?.url?.endsWith('/user')) {
          this.tokenExpiredService.logout();
          setTimeout(() => {
            this.swalNotifs.closeAllSwals();
          }, 0);
        }
        return [false];
      })
    );
  }
  private isUserValid(user: any, role: string | null): boolean {
    return user && user.status !== 'BLOCKED' &&
      !this.tokenExpiredService.isTokenExpired(this.tokenExpiredService.getAuthFromLocalStorage()?.expiresIn) &&
      role === user.roles[0].name;
  }

  updateCurrentPartnerAndUser(user: any): void {
    const oldCp = JSON.parse(localStorage.getItem('cp') || '{}');
    const currentUser : any = this.authService.currentUserValue
    const hasCurrentPartner = user.partners.some((partner: any) =>
        partner.id === oldCp.id && partner.name === oldCp.name);
    if(!hasCurrentPartner){
      const newCp = user.partners[0]
      this.authService.currentPartnerSubject.next(newCp)
      this.authService.setCurrentPartnerFromLocalStorage(newCp)
      this.authService.currentUserSubject.next({...currentUser,partners:user.partners})
    }
  }
}
